var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_preferences_account_setup_info")) +
                    " "
                )
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_company")) + " ")
                      ]),
                      _c("p", { staticClass: "ml-2 text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_balance_sheet")) + " ")
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [0, 0] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_opening_balance_equity"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeBSOpening
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.balanceSheet
                                                  .account_setup_opening_balance_equity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.balanceSheet,
                                                  "account_setup_opening_balance_equity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.balanceSheet\n                          .account_setup_opening_balance_equity\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_retained_earning"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeBSRetained
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.balanceSheet
                                                  .account_setup_retained_earning,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.balanceSheet,
                                                  "account_setup_retained_earning",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.balanceSheet.account_setup_retained_earning\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_exchange_rate_variance_gain"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeBSExchangeGain
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.balanceSheet
                                                  .account_setup_exchange_rate_variance_gain,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.balanceSheet,
                                                  "account_setup_exchange_rate_variance_gain",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.balanceSheet\n                          .account_setup_exchange_rate_variance_gain\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_exchange_rate_variance_loss"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeBSExchangeLoss
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.balanceSheet
                                                  .account_setup_exchange_rate_variance_loss,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.balanceSheet,
                                                  "account_setup_exchange_rate_variance_loss",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.balanceSheet\n                          .account_setup_exchange_rate_variance_loss\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_sales_and_purchase")) + " "
                        )
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [0, 0] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_sales_revenue")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPSRevenue
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_revenue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_revenue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_sales_revenue\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_sales_discount_invoice_line"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeSPSDiscountLines
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_discount_invoice_line,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_discount_invoice_line",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_sales_discount_invoice_line\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_sales_discount_invoice_total"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeSPSDiscountTotal
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_discount_invoice_total,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_discount_invoice_total",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_sales_discount_invoice_total\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_sales_return")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPSReturn
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_return,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_return",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_sales_return\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_sales_delivery")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPSDelivery
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_delivery,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_delivery",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_sales_delivery\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_sales_bad_debt")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPSBadDebt
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_sales_bad_debt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_sales_bad_debt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.salesPurchase.account_setup_sales_bad_debt"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_unearned_revenue"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPURevenue
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_unearned_revenue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_unearned_revenue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_unearned_revenue\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_unbilled_sales")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPUSales
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_unbilled_sales,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_unbilled_sales",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_unbilled_sales\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_unbilled_receivables"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPUReceivables
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_unbilled_receivables,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_unbilled_receivables",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_unbilled_receivables\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_purchase_discount"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPDiscount
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_discount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_discount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_purchase_discount\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_purchase_cogs")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: { change: _vm.onChangeSPPCOGS },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_cogs,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_cogs",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_purchase_cogs\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_purchase_shipping"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPPShipping
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_shipping,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_shipping",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_purchase_shipping\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_purchase_return")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPPReturn
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_return,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_return",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase.account_setup_purchase_return\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_purchase_bad_debt"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPPBadDebt
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_bad_debt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_bad_debt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.salesPurchase.account_setup_purchase_bad_debt"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_unbilled_payable"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPUPayable
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_unbilled_payables,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_unbilled_payables",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_unbilled_payables\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_rounding_account_gain"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeSPRoundingAccountGain
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_rounding_account_gain,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_rounding_account_gain",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_rounding_account_gain\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_rounding_account_loss"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeSPRoundingAccountLoss
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_rounding_account_loss,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_rounding_account_loss",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_rounding_account_loss\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_purchase_price_variance"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeSPPPriceVariance
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.salesPurchase
                                                  .account_setup_purchase_price_variance,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.salesPurchase,
                                                  "account_setup_purchase_price_variance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.salesPurchase\n                          .account_setup_purchase_price_variance\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_ar_ap")) + " ")
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [0, 0] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_account_receivable"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeARAPAReceivables
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.arap
                                                  .account_setup_account_receiviables,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.arap,
                                                  "account_setup_account_receiviables",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.arap.account_setup_account_receiviables\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_account_payable")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeARAPAPayables
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.arap
                                                  .account_setup_account_payables,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.arap,
                                                  "account_setup_account_payables",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.arap.account_setup_account_payables"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_invoice_price_variance"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeARAPAVariance
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.arap
                                                  .account_setup_invoice_price_variance,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.arap,
                                                  "account_setup_invoice_price_variance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.arap.account_setup_invoice_price_variance\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_ar")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPPrepaymentAr
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.arap
                                                  .account_setup_prepayment_ar,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.arap,
                                                  "account_setup_prepayment_ar",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.arap.account_setup_prepayment_ar"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_ap")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeSPPrepaymentAp
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.arap
                                                  .account_setup_prepayment_ap,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.arap,
                                                  "account_setup_prepayment_ap",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.arap.account_setup_prepayment_ap"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_inventory")) + " ")
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [0, 0] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_inventory")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: { change: _vm.onChangeInv },
                                            model: {
                                              value:
                                                _vm.valPref.inventory
                                                  .account_setup_inventory,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.inventory,
                                                  "account_setup_inventory",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.inventory.account_setup_inventory"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_inventory_non_stock"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeInvNonStock
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.inventory
                                                  .account_setup_inventory_non_stock,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.inventory,
                                                  "account_setup_inventory_non_stock",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.inventory.account_setup_inventory_non_stock\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_inventory_waste")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeInvWaste
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.inventory
                                                  .account_setup_inventory_waste,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.inventory,
                                                  "account_setup_inventory_waste",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.inventory.account_setup_inventory_waste\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_stock_adjustment"
                                            )
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change: _vm.onChangeInvStockAdj
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.inventory
                                                  .account_setup_stock_adjustment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.inventory,
                                                  "account_setup_stock_adjustment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.inventory.account_setup_stock_adjustment\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_stock_variance")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeInvStockVariance
                                            },
                                            model: {
                                              value:
                                                _vm.valPref.inventory
                                                  .account_setup_stock_variance,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.inventory,
                                                  "account_setup_stock_variance",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                        valPref.inventory.account_setup_stock_variance\n                      "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", { staticClass: "text-subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_tax")) + " ")
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [0, 0] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          "label-align": "left",
                                          layout: "horizontal",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: { label: _vm.$t("lbl_vat_in") }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: { change: _vm.onChangeVatIn },
                                            model: {
                                              value:
                                                _vm.valPref.tax
                                                  .account_setup_vat_in,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.tax,
                                                  "account_setup_vat_in",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.tax.account_setup_vat_in"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_vat_out")
                                          }
                                        },
                                        [
                                          _c("CSelectAccountingAccount", {
                                            attrs: {
                                              "for-list": false,
                                              "label-key": [
                                                "code",
                                                "description"
                                              ],
                                              "prop-account-name": "trigger"
                                            },
                                            on: { change: _vm.onChangeVatOut },
                                            model: {
                                              value:
                                                _vm.valPref.tax
                                                  .account_setup_vat_out,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.valPref.tax,
                                                  "account_setup_vat_out",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "valPref.tax.account_setup_vat_out"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }